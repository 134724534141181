<template>
	<div class="offer-settings">
		<div v-for="item in content" :key="item.key" class="offer-settings__item">
			<or-checkbox
				:label="t(item.title)"
				:checked="selected.includes(item.key)"
				@update:checked="select(item, $event)"
			/>
		</div>
	</div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const emit = defineEmits(['update'])

const store = useStore()
const { t } = useI18n()

const content = [
	{
		title: 'web.amenities',
		key: 'amenities'
	},
	{
		title: 'web.developer',
		key: 'developer'
	},
	{
		title: 'web.external_features',
		key: 'external_feats'
	},
	{
		title: 'web.internal_features',
		key: 'internal_feats'
	},
	{
		title: 'web.region',
		key: 'region'
	},
	{
		title: 'web.documentation',
		key: 'docs'
	}
]
const selected = computed(() => store.state.broker.offer.properties)

function select(item, checked) {
	const newState = [...selected.value]
	if (checked) {
		newState.push(item.key)
	} else {
		newState.splice(newState.indexOf(item.key), 1)
	}

	emit('update', newState)
}
</script>

<style lang="scss">
.offer-settings {
	width: 100%;
	padding: 15px;
	background: #ffffff;
	box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	max-height: 300px;
	display: flex;
	flex-direction: column;
	gap: 13px;
	overflow: auto;
	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;

		.main-checkbox {
			&__toggler {
				border-radius: 50%;
			}
		}
	}
}
</style>
