<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6057_20922)">
      <path d="M10.6867 2.61969H2.00048C1.18543 2.61969 0.523438 3.15036 0.523438 3.80374V13.7945C0.523438 14.4479 1.18543 14.9786 2.00048 14.9786H10.6867C11.5018 14.9786 12.1637 14.4479 12.1637 13.7945V3.80374C12.1599 3.15036 11.4979 2.61969 10.6867 2.61969ZM11.1268 13.7915C11.1268 13.9878 10.9278 14.1473 10.6829 14.1473H1.99665C1.75175 14.1473 1.55277 13.9878 1.55277 13.7915V3.80374C1.55277 3.60742 1.75175 3.44791 1.99665 3.44791H10.6829C10.9278 3.44791 11.1268 3.60742 11.1268 3.80374V13.7915Z" fill="white"/>
      <path d="M14.007 6.10352e-05H5.32079C4.50574 6.10352e-05 3.84375 0.530736 3.84375 1.18411C3.84375 1.41417 4.07334 1.59822 4.36033 1.59822C4.64732 1.59822 4.87691 1.41417 4.87691 1.18411C4.87691 0.987791 5.07589 0.828282 5.32079 0.828282H14.007C14.2519 0.828282 14.4509 0.987791 14.4509 1.18411V11.1749C14.4509 11.3712 14.2519 11.5307 14.007 11.5307C13.72 11.5307 13.4904 11.7148 13.4904 11.9448C13.4904 12.1749 13.72 12.359 14.007 12.359C14.8221 12.359 15.4841 11.8283 15.4841 11.1749V1.18411C15.4841 0.530736 14.8221 6.10352e-05 14.007 6.10352e-05Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_6057_20922">
        <rect width="15" height="15" fill="white" transform="translate(0.5 6.10352e-05)"/>
      </clipPath>
    </defs>
  </svg>
</template>