<template>
	<div class="create-offer">
		<div class="container">
			<div class="create-offer__body">
				<div class="create-offer__title input-wrapper">
					<label>{{ t('web.title') }}</label>
					<or-input
						v-model="form.title"
						placeholder="Title"
						class="main-type"
						@input="clearError('title')"
						:error="errors.title"
					/>
					<p>{{ t('web.create_offer_text') }}</p>
				</div>
				<div class="create-offer__selects">
					<or-drop-down>
						<template #toggle>
							<or-input
								:placeholder="
									t('web.list_of_units') +
									(unitsCount > 0 ? ' (' + unitsCount + ')' : '')
								"
								class="main-type"
								icon-before="project"
								icon-after="arrow"
								:error="errors.units"
								readonly
							/>
						</template>
						<template #content>
							<ListOfUnits />
						</template>
					</or-drop-down>
					<or-drop-down>
						<template #toggle>
							<or-input
								:resize="false"
								:placeholder="
									selectedProperties.length > 0
										? selectedProperties
										: t('web.offer_settings')
								"
								class="main-type"
								icon-before="settings"
								icon-after="arrow"
								:error="errors.properties"
								readonly
							/>
							<!-- @input="clearError('properties')" -->
						</template>
						<template #content>
							<OfferSettings @update="updateProperties" />
						</template>
					</or-drop-down>
				</div>
				<div class="create-offer__selects">
          <or-select-button-group v-model="measure_units" :model-data="areaConfig" />
					<or-drop-down>
						<template #toggle>
							<or-input
								:resize="false"
								:placeholder="moneyReverseMap[currency]"
								class="main-type"
								icon-before="money"
								icon-after="arrow"
								readonly
							/>
							<!-- @input="clearError('properties')" -->
						</template>
						<template #content>
              <offer-currency v-model="currency" />
            </template>
					</or-drop-down>
				</div>
				<div class="create-offer__link">
					<div v-if="link && link.length > 0" class="create-offer__link-input">
						<or-input
							class="main-type"
							v-model="link"
							readonly
							icon-after="copy"
							@clickIconAfter="copyLink"
						/>
						<a target="_blank" :href="link">
							<external-link />
						</a>
					</div>
					<div class="create-offer__btns">
						<or-button
							v-if="!link || link.length === 0"
							class="create-offer__btn"
							@click="createOffer"
							:height="48"
						>
							{{ t('web.generate_link') }}
						</or-button>
						<a
							v-if="link?.length > 0"
							:href="link"
							target="_blank"
							class="button main create-offer__btn"
						>
							{{ t('general.open_offer') }}
						</a>
						<or-button
							v-if="form.id"
							class="create-offer__btn"
							@click="createOffer"
							:height="48"
						>
							{{ t('general.save') }}
						</or-button>
						<or-button class="create-offer__btn" @click="newOffer" :height="48">
							{{ t('web.reset') }}
						</or-button>
						<!-- <or-button class="create-offer__btn" :height="48">
							{{ t('web.send_via_messenger') }}
						</or-button> -->
					</div>
				</div>
				<div v-if="offers?.length" class="create-offer__offers">
					<p
						:class="{ active: activeList }"
						@click="() => (activeList = !activeList)"
					>
						{{ t('web.sent_offers') }}
						<or-icon class="icon-arrow" />
					</p>
					<transition>
						<div v-if="activeList" class="create-offer__list">
							<OfferItem
								v-for="offer in offers"
								:key="offer.id"
								:content="offer"
								@deleteOffer="deleteOffer"
							/>
						</div>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import OfferItem from '@/modules/broker/components/Offer/OfferItem.vue'
import ListOfUnits from '@/modules/broker/components/Units/ListOfUnits.vue'
import OfferSettings from '@/modules/broker/components/Offer/OfferSettings.vue'
import { createRequestBroker } from '@/api/requestBuilder'
import requestBrokerConfigs from '@/api/requestBrokerConfigs'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import ExternalLink from '@/components/svg/ExternalLink.vue'
import OfferCurrency from '@/modules/broker/components/Offer/OfferCurrency.vue'
import OrSelectButton from "@/components/global/orSelectButtonGroup.vue";
import OrSelectButtonGroup from "@/components/global/orSelectButtonGroup.vue";
import {money, moneyReverseMap} from "@/enums/moneyСollections";
import {areaConfig} from "@/enums/areaCollections";

const store = useStore()
const router = useRouter()
const errors = ref({})
const offers = ref([])
const activeList = ref(false)
const { t } = useI18n()

await getOffers()

const form = computed(() => store.state.broker.offer)
const link = computed(() => {
	return form.value.url
})
const currency = computed({
  get() {
    return form.value.currency ?? money[store.state.activeMoney]
  },
  set(value) {
    if (form.value.currency || (!form.value.currency && store.state.activeMoney !== value)) {
      form.value.currency = value
    }
  }
})
const measure_units = computed({
  get() {
    return form.value.measure_units ?? store.state.areaUnit
  },
  set(value) {
    if (form.value.measure_units || (!form.value.measure_units && store.state.areaUnit !== value)) {
      form.value.measure_units = value
    }
  }
})

const propertiesContent = [
	{
		title: 'web.amenities',
		key: 'amenities'
	},
	{
		title: 'web.developer',
		key: 'developer'
	},
	{
		title: 'web.external_features',
		key: 'external_feats'
	},
	{
		title: 'web.internal_features',
		key: 'internal_feats'
	},
	{
		title: 'web.region',
		key: 'region'
	},
	{
		title: 'web.documentation',
		key: 'docs'
	}
]
const selectedProperties = computed(() =>
	propertiesContent
		.filter((prop) => store.state.broker.offer.properties.includes(prop.key))
		.map((prop) => t(prop.title))
		.join(', ')
)
const unitsCount = computed(() => store.state.broker.offer.units.length)

Object.keys(form.value).forEach((key) => {
	// Set field errors
	errors.value[key] = { text: '', status: false }
})

function clearError(type) {
	errors.value[type].status = false
	errors.value[type].text = ''
}

function updateProperties(evt) {
	store.commit('patchOffer', { properties: [...evt] })
	clearError('properties')
}

function updateUnits(evt) {
	store.commit('patchOffer', { units: [...evt] })
	this.clearError('units')
}

function deleteOffer(id) {
	offers.value = offers.value.filter((itm) => itm.id !== id)
}

async function getOffers() {
	await createRequestBroker(requestBrokerConfigs.GETOffers).then((response) => {
		offers.value = [...response.data]
	})
}

async function copyLink() {
	if (!link.value) {
		return
	}

	navigator.clipboard.writeText(link.value).then(() => {
		store.commit('showNotif', {
			type: 'success',
			text: t('notification.successCopy')
		})
	})
}

async function newOffer() {
	await store.dispatch('loadOfferForEdit')
}

async function createOffer() {
	const items = store.state.broker.offer.units.map((itm) => itm.id)
	const { title, properties, id } = store.state.broker.offer
	const payload = {}

	if (!items.length) {
		errors.value.units.status = true

		return
	}

	payload.items = items

	if (properties.length) {
		payload['properties'] = properties
	}

	if (title) {
		payload['title'] = title
	}

	if (id) {
		payload['id'] = id
	}

  payload['currency'] = currency.value
  payload['units'] = measure_units.value

	createRequestBroker(
		id
			? requestBrokerConfigs.PATCHUpdateOffer
			: requestBrokerConfigs.POSTCreateOffer,
		{
			jsonPayload: {
				...payload
			}
		}
	)
		.then((response) => {
			if (response.status) {
				if (response.response.id)
					store.commit('patchOffer', {
						id: response.response.id,
						slug: response.response.id,
						url: response.response.url
					})
				// this.link = response.response.url
				//
				// this.form.title = ''
				// this.form.properties = []
				//
				// store.commit('clearUnits')
				// this.isSubmitSuccess = true
				store.commit('showNotif', {
					type: 'success',
					text: 'The offer has been successfully created!'
				})

				getOffers()
			}
		})
		.catch((err) => {
			if (err?.errors && Object.keys(err.errors).length) {
				Object.entries(err.errors).forEach(([key, value]) => {
					if (errors.value[key]) {
						errors.value[key].status = true
						errors.value[key].text = value.join(', ')
					}
				})
			}
		})
}
</script>

<style lang="scss">
.create-offer {
	margin-top: 40px;
	padding-bottom: 80px;
	.container {
		overflow: visible;
	}

	&__body {
		display: flex;
		flex-direction: column;
		gap: 40px;
		max-width: 720px;
	}

	&__title {
		max-width: 340px;
	}
	&__selects {
		display: flex;
		gap: 40px;
		align-items: center;
		width: 100%;
		& > * {
			width: 100%;
		}
		& .dropdown-content {
			min-width: 100%;
		}
	}
	&__link {
		display: flex;
		flex-direction: column;
		gap: 20px;
		&-input {
			display: flex;
			align-items: center;
			gap: 12px;
			width: 100%;
			& > label {
				width: 100%;
			}
			svg {
				width: 24px;
				fill: #c99d56;
			}
		}
	}
	&__offers {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 40px 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		& > p {
			cursor: pointer;
			margin: 0;
			font-size: 16px;
			font-weight: 700;
			line-height: normal;
			display: flex;
			align-items: center;
			gap: 10px;
			i {
				transition: all 0.3s ease;
			}
			&.active {
				i {
					transform: rotate(0deg);
				}
			}
		}
	}
	&__list {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		column-gap: 20px;
		row-gap: 10px;
	}
	&__btns {
		display: flex;
		align-items: center;
		gap: 10px;
		flex-wrap: wrap;
	}
	&__btn {
		height: 48px;
		text-align: center;
	}

	&__measure {
		display: flex;
		gap: 10px;
		& > button {
			width: 100%;
		}
	}
	.v-enter-active,
	.v-leave-active {
		transition: opacity 0.2s ease;
	}
	.v-enter-from,
	.v-leave-to {
		opacity: 0;
	}
	@media screen and (max-width: 768px) {
		&__selects {
			gap: 20px;
		}
	}
	@media screen and (max-width: 479px) {
		&__body {
			gap: 24px;
		}
		&__offers {
			padding: 20px 0;
		}
		&__btn.button.main {
			font-size: 12px;
			max-width: 120px;
			height: 32px !important;
		}
		&__selects {
			flex-direction: column;
		}
		&__list {
			grid-template-columns: 1fr;
		}
	}
}
</style>
