<template>
	<div class="offer-item">
		<a
			:href="content.url"
      target="_blank"
			class="offer-item__left"
		>
			<p class="offer-item__name">{{ content.title }}</p>
			<p class="offer-item__date">{{ date }}</p>
			<p class="offer-item__info">
				<span>{{ content.units?.length }}</span> units in offer
				<!-- <br /> -->
				<!-- <span>Studio, 2 BR.</span> -->
			</p>
			<p class="offer-item__status" :class="{ viewed: content.views_count > 0 }">
				{{
          content.views_count > 0 ? this.$t('web.viewed') : this.$t('web.not_viewed')
				}}
				<Viewed v-if="content.views_count > 0" />
				<NotViewed v-else />
        {{ content.views_count > 0 ? content.views_count : '' }}
			</p>
		</a>
		<div class="offer-item__right">
			<or-button @click="loadOffer">
				<Edit />
			</or-button>
      <or-button @click="makeOfferCopy">
        <SvgCopy />
      </or-button>
			<or-button @click="deleteOffer"><Trash /> </or-button>
		</div>
	</div>
</template>

<script>
import Edit from '@/components/svg/Edit.vue'
import Trash from '@/components/svg/Trash.vue'
import Return from '@/components/svg/Return.vue'
import Viewed from '@/components/svg/Viewed.vue'
import NotViewed from '@/components/svg/NotViewed.vue'
import { createRequestBroker } from '@/api/requestBuilder'
import requestBrokerConfigs from '@/api/requestBrokerConfigs'
import OrLink from '@/components/global/orLink.vue'
import SvgCopy from "@/components/svg/SvgCopy.vue";

export default {
	name: 'OfferItem',
	components: {SvgCopy, OrLink, NotViewed, Viewed, Return, Trash, Edit },
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	},
  computed: {
    date() {
      const date = new Date(this.content.date_generated)
      return new Intl.DateTimeFormat("en-GB", {
        dateStyle: 'short',
        timeStyle: 'medium',
      }).format(date)
    }
  },
	methods: {
		toOffer() {
			this.$router.push({
				name: 'BrokerOffer',
				params: {
					lang: this.$store.state.uiLanguage,
					offer_id: this.content.id
				}
			})
		},
		loadOffer() {
			this.$store.dispatch('loadOfferForEdit', this.content.id)
		},
    async makeOfferCopy() {
      await this.$store.dispatch('makeOfferCopy', this.content.id)
      this.$store.commit('showNotif', {
        type: 'success',
        text: this.$t('web.notification_offer_has_been_copied')
      })
    },
		deleteOffer() {
			createRequestBroker(requestBrokerConfigs.DELOffer, {
				routerPayload: {
					id: this.content.id
				}
			}).then((response) => {
				if (response.status) {
					this.$emit('deleteOffer', this.content.id)
          this.$store.commit('showNotif', {
            type: 'success',
            text: this.$t('web.notification_success_delete_offer')
          })
				}
			})
		}
	}
}
</script>

<style lang="scss">
.offer-item {
	border-radius: 4px;
	border: 1px solid var(--opacity-black-10, rgba(0, 0, 0, 0.1));
	background: var(--opacity-black-2, rgba(0, 0, 0, 0.02));
	padding: 10px 20px;
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	&__left {
		display: flex;
		flex-direction: column;
		gap: 5px;
		width: 100%;
		p {
			margin: 0;
		}
	}
	&__name {
		font-size: 16px;
		font-weight: 700;
		line-height: normal;
		margin: 0;
		color: var(--Text-1, #000);
	}
	&__date {
		color: var(--secondary-1, #808080);
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
	}
	&__info {
		font-size: 12px;
		font-weight: 600;
		line-height: normal;
		color: var(--secondary-1, #808080);
		span {
			color: var(--primary-2, #b98024);
		}
	}
	&__status {
		color: var(--red, #f96060);
		font-size: 10px;
		font-weight: 600;
		line-height: normal;
		margin: 0;
		display: flex;
		align-items: center;
		gap: 5px;
		&.viewed {
			color: var(--secondary-1, #808080);
		}
	}
	&__right {
		display: flex;
		flex-direction: column;
		gap: 10px;
		& > button,
		a {
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			svg,
			span {
				display: block;
				width: 15px;
				height: 15px;
			}
		}
	}
	@media screen and (max-width: 800px) {
		padding: 10px;
	}
}
</style>
