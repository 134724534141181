<template>
	<div class="list-of-units">
		<div
			class="list-of-units__item"
			v-for="project in projects"
			:key="project.id"
		>
			<div class="list-of-units__title">
				{{ this.$t('web.project') }}
				<span>{{ project.title }}</span>
			</div>

			<table class="list-of-units__list">
				<tbody>
					<tr v-for="unit in project.units" :key="unit.id">
						<td class="">{{ unit.beds_count }} BR</td>
						<td>{{ square(unit) }} ft2</td>
						<td class="">{{ unit.furnished }}</td>
						<td class="">{{ unit.type }}</td>
						<td class="">{{ handoverYear(unit) }}</td>
						<td>
							<span>{{ money(unit).price.toLocaleString() }}</span>
							{{ money(unit).symbol }}
						</td>
						<td>
							<button
								class="btn list-of-units__del-btn"
								@click.stop.prevent="deleteUnit(unit)"
								type="button"
							>
								<Trash />
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import Trash from '@/components/svg/Trash.vue'
import { money, simbol } from '@/enums/moneyСollections.js'

export default {
	name: 'ListOfUnits',
	components: { Trash },
	methods: {
		deleteUnit(payload) {
			this.$store.commit('deleteUnit', payload)
		},
		money(content) {
			return {
				price: content.price[money[this.$store.state.activeMoney]],
				symbol: simbol[this.$store.state.activeMoney]
			}
		},
		square(content) {
			return content.area[this.$store.state.areaUnit]
		},
		handoverYear(content) {
			return content.post_handover_term
				? new Date(content.post_handover_term * 1000).getFullYear()
				: null
		}
	},
	computed: {
		units() {
			return this.$store.state.broker.offer.units
		},
		projects() {
			return this.units.reduce((list, item) => {
				const idx = list.findIndex((itm) => itm.id === item.project.id)
				if (idx === -1) {
					list.push({ ...item.project, units: [item] })
				} else {
					list[idx].units.push(item)
				}

				return list
			}, [])
		}
	}
}
</script>

<style lang="scss">
.list-of-units {
	max-width: 90vw;
	padding: 10px;
	background: #ffffff;
	box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	max-height: 300px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow: auto;
	&__item {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
	&__title {
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		padding-bottom: 5px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		color: var(--secondary-1, #808080);
		display: flex;
		align-items: center;
		gap: 10px;
		span {
			color: #000;
			font-weight: 700;
		}
	}
	&__list {
		border-spacing: 0 10px;
		overflow: auto;
		padding: 0 10px;
		margin: 0 -10px;
		td {
			margin: 0;
			font-size: 14px;
			font-weight: 400;
			line-height: 18px;
			flex-shrink: 0;
			padding: 0 8px;
			white-space: nowrap;

			span {
				color: var(--01, #c99d56);
			}
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}
		}
	}
	&__del-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 18px;
		height: 18px;
		svg {
			width: 16px;
			height: 16px;
			path {
				fill: #c99d56;
			}
		}
	}
	@media screen and (max-width: 479px) {
		max-height: 250px;
	}
	@media screen and (max-width: 375px) {
		&__list {
			p {
				min-width: unset;
			}
			& .list-of-units__rooms {
				min-width: 40px;
			}
		}
	}
}
</style>
