<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
	>
		<path
			d="M16.2114 8.67107C15.8878 8.67107 15.6255 8.93342 15.6255 9.25702V17.851C15.6255 18.3895 15.1874 18.8276 14.6489 18.8276H2.1485C1.61001 18.8276 1.17191 18.3895 1.17191 17.851V5.35065C1.17191 4.81216 1.61001 4.37406 2.1485 4.37406H10.7425C11.0661 4.37406 11.3285 4.11171 11.3285 3.7881C11.3285 3.4645 11.0661 3.20215 10.7425 3.20215H2.1485C0.963819 3.20215 0 4.16597 0 5.35065V17.851C0 19.0357 0.963819 19.9995 2.1485 19.9995H14.6489C15.8336 19.9995 16.7974 19.0357 16.7974 17.851V9.25702C16.7974 8.93342 16.535 8.67107 16.2114 8.67107Z"
			fill="white"
		/>
		<path
			d="M19.5997 1.50472L18.4948 0.399798C17.9618 -0.133266 17.0944 -0.133266 16.5612 0.399798L7.72218 9.2389C7.64038 9.3207 7.58464 9.42488 7.5619 9.53833L7.00943 12.3006C6.97103 12.4927 7.03114 12.6913 7.1697 12.8298C7.28068 12.9408 7.43018 13.0014 7.58401 13.0014C7.62226 13.0014 7.6607 12.9977 7.6989 12.9901L10.4611 12.4376C10.5746 12.4149 10.6788 12.3591 10.7606 12.2773L19.5997 3.43825C19.5997 3.43825 19.5997 3.43825 19.5997 3.43821C20.1328 2.90519 20.1328 2.03782 19.5997 1.50472ZM10.0574 11.3232L8.33099 11.6685L8.67631 9.94217L15.8707 2.74768L17.2518 4.12882L10.0574 11.3232ZM18.771 2.60959L18.0805 3.30016L16.6993 1.91902L17.3899 1.2285C17.466 1.15232 17.59 1.15228 17.6661 1.22846L18.771 2.33337C18.8472 2.40951 18.8472 2.53346 18.771 2.60959Z"
			fill="white"
		/>
	</svg>
</template>
