<template>
	<div class="offer-currency" role="listbox">
		<!--	Добавляем класс active при выборе валюты	-->
		<div
      v-for="(value, key) in money"
      :key="key"
      class="item"
      :class="{active: modelValue === value}"
      role="option"
      :aria-selected="modelValue === value"
      @click="() => select(value)"
    >
      {{ key }}
    </div>
	</div>
</template>

<script setup>
import {money} from "@/enums/moneyСollections";

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

function select(value) {
  emit('update:modelValue', value)
}
</script>

<style scoped lang="scss">
.offer-currency {
	width: 100%;
	padding: 8px;
	background: #ffffff;
	box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	max-height: 300px;
	display: flex;
	flex-direction: column;
	gap: 2px;
	overflow: auto;
	.item {
		border-radius: 4px;
		overflow: hidden;
		padding: 12px;
		transition: all 0.2s ease;
		cursor: pointer;
		font-size: 16px;
		line-height: 1;
		flex-shrink: 0;
		&.active {
			background: #c99d56;
			color: white;
		}
		&:hover {
			background: rgba(0, 0, 0, 0.1);
		}
	}
}
</style>
