<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="20"
		viewBox="0 0 16 20"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M0.549588 0.683761L0.0866251 1.79487C-0.218323 2.52675 0.319401 3.33333 1.11227 3.33333H14.4456C15.2385 3.33333 15.7762 2.52675 15.4712 1.79487L15.0083 0.683761C14.8358 0.269709 14.4312 0 13.9826 0H1.57523C1.12667 0 0.72211 0.269709 0.549588 0.683761ZM14.4456 2.22222H1.11227L1.57523 1.11111L13.9826 1.11111L14.4456 2.22222ZM12.6267 5.55556H2.93121C2.28862 5.55556 1.78001 6.09902 1.82253 6.7402L2.55949 17.8513C2.59822 18.4351 3.08306 18.8889 3.66817 18.8889H11.8897C12.4748 18.8889 12.9596 18.4351 12.9984 17.8513L13.7353 6.7402C13.7779 6.09902 13.2692 5.55556 12.6267 5.55556ZM2.93121 4.44444C1.64603 4.44444 0.628803 5.53138 0.713858 6.81374L1.45082 17.9248C1.52826 19.0925 2.49796 20 3.66817 20H11.8897C13.0599 20 14.0296 19.0925 14.107 17.9248L14.844 6.81374C14.9291 5.53138 13.9118 4.44444 12.6267 4.44444H2.93121ZM5.00115 7.77778C5.00115 7.47095 4.75242 7.22222 4.4456 7.22222C4.13877 7.22222 3.89004 7.47095 3.89004 7.77778V16.6667C3.89004 16.9735 4.13877 17.2222 4.4456 17.2222C4.75242 17.2222 5.00115 16.9735 5.00115 16.6667V7.77778ZM7.77893 7.22222C8.08576 7.22222 8.33449 7.47095 8.33449 7.77778V16.6667C8.33449 16.9735 8.08576 17.2222 7.77893 17.2222C7.47211 17.2222 7.22338 16.9735 7.22338 16.6667V7.77778C7.22338 7.47095 7.47211 7.22222 7.77893 7.22222ZM11.6678 7.77778C11.6678 7.47095 11.4191 7.22222 11.1123 7.22222C10.8054 7.22222 10.5567 7.47095 10.5567 7.77778V16.6667C10.5567 16.9735 10.8054 17.2222 11.1123 17.2222C11.4191 17.2222 11.6678 16.9735 11.6678 16.6667V7.77778Z"
			fill="white"
		/>
	</svg>
</template>
